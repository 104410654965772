document.addEventListener("turbolinks:load", (_) => {
  let cardElement = document.querySelector('#card-element')
  if (cardElement !== null) {
    setupStripe()
  }
})

function setupStripe() {
  const stripe_key = document.querySelector("meta[name='stripe-key']").getAttribute('content')
  const stripe = Stripe(stripe_key, {
    locale: 'da'
  })
  const elements = stripe.elements()
  const card = elements.create('card')
  card.mount('#card-element')
  let displayError = document.getElementById('card-errors')

  card.addEventListener('change', (event) => {
    if (event.error)
      displayError.textContent = event.error.message
    else
      displayError.textContent = ''
  })

  const form = document.querySelector('.payment-form')

  let paymentIntentId = form.dataset.paymentIntent;
  let setupIntentId = form.dataset.setupIntent;

  if (paymentIntentId) {
    if (form.dataset.status == "requires_action") {
      stripe.confirmCardPayment(paymentIntentId, {
        setup_future_usage: form.dataset.futureUsage
      }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          form.querySelector('#card-details').classList.remove('d-none')
          cleanSubmitDisabled(form)
        } else {
          form.submit()
        }
      })
    }
  }
  form.addEventListener('submit', (event) => {
    event.preventDefault()

    const name = form.querySelector('#name_on_card').value
    const payment_data = {
      payment_method_data: {
        card: card,
        billing_details: {
          name: name
        }
      }
    }

    // Complete the payment intent
    if (paymentIntentId) {
      stripe.confirmCardPayment(paymentIntentId, {
        payment_method: payment_data.payment_method_data,
        setup_future_usage: form.dataset.futureUsage,
        save_payment_method: true
      }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          form.querySelector('#card-details').classList.remove('d-none')
          cleanSubmitDisabled(form)
        } else {
          form.submit()
        }
      })
      // Update card
    } else if (setupIntentId) {
      stripe.confirmCardSetup(setupIntentId, {
        payment_method: payment_data.payment_method_data
      }).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          cleanSubmitDisabled(form)
        } else {
          addHiddenField(form, 'payment_method_id', result.setupIntent.payment_method)
          form.submit()
        }
      })
    } else {
      payment_data.payment_method_data.type = 'card'
      stripe.createPaymentMethod(payment_data.payment_method_data).then((result) => {
        if (result.error) {
          displayError.textContent = result.error.message
          cleanSubmitDisabled(form)
        } else {
          addHiddenField(form, 'payment_method_id', result.paymentMethod.id)
          form.submit()
        }
      })
    }
  })
}

function cleanSubmitDisabled(form) {
  form.querySelector('.btn').removeAttribute('disabled')
}

function addHiddenField(form, name, value) {
  const hiddenInput = document.createElement('input')
  hiddenInput.setAttribute('type', 'hidden')
  hiddenInput.setAttribute('name', name)
  hiddenInput.setAttribute('value', value)
  form.appendChild(hiddenInput)
}
